import React from "react";
import { useSelector } from "react-redux";

import useBlocks from "../../../pages/Blocks/hooks/use-blocks";
import {selectBlockById, selectBlocks} from "../../../store/blocksSlice";
import RadioButton from "../../RadioButton";

import "./style.scss";

interface IVersionOptionsProps {
  optionId?: number;
  setOption(id: number): void;
}

const BlockTypeOptions: React.FC<IVersionOptionsProps> = ({
  optionId,
  setOption,
}) => {
  useBlocks();
  const blocks = useSelector(selectBlocks);
  const blockById = useSelector(selectBlockById);

  const setValue = (id: string) => {
    const optionId = parseInt(id);
    if (optionId) setOption(optionId);
  };

  return (
    <>
      <div className="option__scrollable">
        {blocks?.map((item) => (
          <div className="option__container">
            <RadioButton
              id={`block-radio-${item.id}`}
              value={item.id.toString()}
              label={item.name}
              checked={optionId === item.id}
              setValue={setValue}
              disabled={
                blockById &&
                (!blockById[item.id]?.user_svg?.file_name ||
                  (blockById[item.id]?.input_connection_points.length === 0 &&
                    blockById[item.id]?.out_connection_points.length === 0))
              }
            >
              {blockById &&
                (blockById[item.id]?.user_svg?.file_name ? (
                  <object
                    data={`https://dev-api-new.sys-eng.numdes.com/attachments/user_svg/${
                      blockById[item.id]?.user_svg?.file_name
                    }`}
                    type="image/svg+xml"
                    className="option__block-image"
                  />
                ) : (
                  <div className="option__block-image empty">
                    <div>
                      Файл
                      <br />
                      не загружен
                    </div>
                  </div>
                ))}
            </RadioButton>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlockTypeOptions;
