import React from "react";
import { useSelector } from "react-redux";

import { selectPanelIsShown } from "../../../../store/connectionsSlice";
import { selectSelectedMatrixBlock } from "../../../../store/matrixSlice";
import ConnectionsPanel from "./ConnectionsPanel/connectionsPanel";
import ErrorsPanel from "./ErrorsPanel/errorsPanel";

import "./style.scss";

const BlockImagePanel: React.FC = () => {
  const selectedObject = useSelector(selectSelectedMatrixBlock);
  const connectionsPanelIsShown = useSelector(selectPanelIsShown);

  return (
    <div className="facility-block-image-panel__container">
      {selectedObject?.id && (
        <>
          <div className="facility-block-image-panel__image-row">
            {selectedObject.instance?.user_svg?.file_name ? (
              <>
                <object
                  data={`https://dev-api-new.sys-eng.numdes.com/attachments/user_svg/${selectedObject.instance?.user_svg?.file_name}`}
                  type="image/svg+xml"
                  className="parameters__image"
                />
              </>
            ) : (
              <div className="parameters__image">Файл не загружен</div>
            )}
          </div>
          {connectionsPanelIsShown ? <ConnectionsPanel /> : <ErrorsPanel />}
        </>
      )}
    </div>
  );
};

export default BlockImagePanel;
